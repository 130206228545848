import { FadeIn, FadeOut } from '../other/utils';
const Scroll = {
    elements: document.getElementsByClassName('atom-scroll'),
    init() {
        if (!this.elements || this.elements.length < 1) {
            return;
        }
        for (const element of this.elements) {
            this.setupScrollHandler(element);
        }
        this.setupwindowHandler();
    },
    /**
     * Setup our scroll button
     * @param element The scroll element
     */
    setupScrollHandler(element) {
        const parent = element.parentElement;
        let destination;
        element.addEventListener('click', (event) => {
            event.preventDefault();
            if (element.classList.contains('atom-scroll-top')) {
                destination = 0;
            }
            else {
                destination = (parent === null || parent === void 0 ? void 0 : parent.clientHeight) + parent.getBoundingClientRect().top + window.scrollY;
            }
            window.scrollTo({ top: destination, behavior: 'smooth' });
        });
    },
    /**
     * Setup the handler for the window functions
     */
    setupwindowHandler() {
        let scrolled = false;
        window.addEventListener('scroll', () => {
            let scrollPosition = window.scrollY;
            for (const element of this.elements) {
                if (element.classList.contains('atom-scroll-top')) {
                    if (scrollPosition > window.innerHeight) {
                        FadeIn(element);
                        scrolled = true;
                    }
                    else if (scrolled && scrollPosition < window.innerHeight) {
                        FadeOut(element);
                        scrolled = false;
                    }
                }
            }
        });
    }
};
export default Scroll;
