var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InitScrollReveal } from "../other/modules";
import { ToggleClass, FadeToggle, FadeOut, AjaxApi, FadeIn } from "../other/utils";
/**
 * Custom scripts for a search element
 * If enabled, the script will loads results through ajax
 */
const Search = {
    elements: document.getElementsByClassName('atom-search'),
    init() {
        if (!this.elements || this.elements.length < 1) {
            return;
        }
        for (const element of this.elements) {
            this.setupSearch(element);
        }
    },
    /**
     * Setups the tabs for each element existing on a page
     * @param element The search element
     */
    setupSearch(element) {
        if (element.classList.contains('atom-search-ajax')) {
            this.setupAjaxSearch(element);
        }
        this.setupToggleSearch(element);
    },
    /**
     * Setups the ajax search functionality for the given element
     * @param element The search element
     */
    setupAjaxSearch(element) {
        const { appear = 'bottom', delay = 300, length = 3, none = '', number = 5, types = '' } = element.dataset;
        const searchForm = element.querySelector('.search-form');
        const searchField = element.querySelector('.search-field');
        const moreAnchor = element.querySelector('.atom-search-all');
        const results = element.querySelector('.atom-search-results');
        const loadingIcon = element.querySelector('.fa-circle-notch');
        let timer;
        let value;
        if (!element.classList.contains('atom-search-ajax')) {
            FadeOut(results);
            return;
        }
        searchField.addEventListener('keyup', (event) => {
            clearTimeout(timer);
            const currentSearchField = event.currentTarget;
            if (currentSearchField.value.length <= length || value === currentSearchField.value) {
                return;
            }
            timer = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                var _a;
                value = currentSearchField.value;
                moreAnchor.href = moreAnchor.href + encodeURI(value);
                results.classList.add('components-loading');
                (_a = results.querySelector('.atom-search-all')) === null || _a === void 0 ? void 0 : _a.remove();
                const response = yield AjaxApi({
                    action: 'public_search',
                    appear: appear,
                    none: none,
                    number: number,
                    search: value,
                    types: types
                });
                if (response.success) {
                    FadeIn(results);
                    results.innerHTML = response.data;
                    results.append(moreAnchor);
                    if (typeof sr !== 'undefined') {
                        if (sr.initialized === false) {
                            InitScrollReveal();
                        }
                        sr.sync();
                    }
                }
                setTimeout(() => {
                    FadeOut(loadingIcon);
                    results.classList.remove('components-loading');
                }, 500);
            }), +delay);
        });
    },
    /**
     * Allows the search-form to be toggled from a single icon
     * @param element The search element
     */
    setupToggleSearch(element) {
        const searchExpandElement = element.querySelector('.atom-search-expand');
        if (!searchExpandElement) {
            return;
        }
        const searchForm = element.querySelector('.atom-search-form');
        const searchField = searchForm.querySelector('.search-field');
        searchExpandElement.addEventListener('click', (event) => {
            event.preventDefault();
            ToggleClass(element, 'atom-search-expanded');
            ToggleClass(searchExpandElement.querySelector('.fas'), ['fa-search', 'fa-times']);
            FadeToggle(searchForm);
            const searchResults = element.querySelector('.atom-search-results');
            if (searchResults.style.display === 'block') {
                searchForm.querySelector('.search-field').value = '';
                FadeOut(searchResults);
            }
            // Close search results when not expanding
            searchField.focus();
        });
    }
};
export default Search;
