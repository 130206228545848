import { FadeOut } from "../other/utils";
/**
 * Defines the custom header scripts
 */
const Modal = {
    elements: document.getElementsByClassName('atom-modal'),
    init() {
        if (!this.elements || this.elements.length < 1) {
            return;
        }
        for (const modal of this.elements) {
            this.setupClickHandler(modal);
        }
    },
    /**
     * Setup the click handler for closing modal
     *
     * @param modal The modal element
     */
    setupClickHandler(modal) {
        const closeModal = modal.querySelector('.atom-modal-close');
        closeModal.addEventListener('click', (event) => {
            event.preventDefault();
            FadeOut(modal);
        });
    }
};
export default Modal;
