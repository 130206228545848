var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AjaxApi, FadeIn, FadeOut } from "../other/utils";
/**
 * Defines the custom header scripts
 */
const Rate = {
    elements: document.getElementsByClassName("atom-rate"),
    init() {
        if (!this.elements || this.elements.length < 1) {
            return;
        }
        for (const element of this.elements) {
            this.setupClickHandler(element);
        }
    },
    /**
     * Setup the click handler for sending rating requests to the back-end
     * @param element The specific rating element
     */
    setupClickHandler(element) {
        let isRating = false;
        const ratingAnchor = element.querySelector(".atom-rate-can .atom-rate-anchor");
        ratingAnchor.addEventListener("click", (event) => __awaiter(this, void 0, void 0, function* () {
            event.preventDefault();
            if (isRating) {
                return;
            }
            const { id = "", max = 5, min = 1 } = element.dataset;
            const starElements = ratingAnchor.querySelectorAll(".atom-rate-star");
            let rating = 0;
            for (const starElement of starElements) {
                if (getComputedStyle(starElement).fontWeight === "900") {
                    rating++;
                }
            }
            const loadingSpinner = element.querySelector(".atom-rate-can .fa-circle-notch");
            FadeIn(loadingSpinner, "inline-block");
            // Actual rating functions
            isRating = true;
            const response = yield AjaxApi({
                action: "public_rate",
                id: id,
                max: +max,
                min: +min,
                rating: rating,
            });
            // Modify our stars according to the rating
            if (response.success && response.data.rating) {
                this.updateStarElementClasses(starElements, response.data.rating);
            }
            setTimeout(() => {
                FadeOut(loadingSpinner);
                isRating = false;
            }, 1500);
        }));
    },
    /**
     * Updates the star element classes according to the new rating, without needing to replace the element
     */
    updateStarElementClasses(starElements, rating) {
        let starKey = 0;
        let ceiledRating = Math.ceil(rating);
        for (const starElement of starElements) {
            starKey++;
            if (starKey < ceiledRating) {
                starElement.classList.add("fas", "fa-star");
                starElement.classList.remove("far", "fa-star-half");
            }
            else if (starKey === ceiledRating) {
                const fraction = rating - Math.floor(rating);
                if (fraction > 0.25 && fraction < 0.75) {
                    starElement.classList.add("fas", "fa-star-half");
                    starElement.classList.remove("far", "fa-star");
                }
                else if (fraction > 0.75) {
                    starElement.classList.remove("far", "fa-star-half");
                    starElement.classList.add("fas", "fa-star");
                }
            }
            else {
                starElement.classList.add("far", "fa-star");
                starElement.classList.remove("fas", "fa-star-half");
            }
        }
    },
};
export default Rate;
