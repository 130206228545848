import { FadeToggle } from "../other/utils";
/**
 * Defines a social share element
 */
const Cart = {
    elements: document.getElementsByClassName('atom-cart-icon'),
    init() {
        if (!this.elements || this.elements.length < 1) {
            return;
        }
        for (const cartElement of this.elements) {
            this.cartHandler(cartElement);
        }
    },
    /**
     * Handles any cart related actions
     *
     * @param cart HTMLElement The passed cart element
     */
    cartHandler(cart) {
        cart.addEventListener('click', (event) => {
            event.preventDefault();
            const cartContent = cart.nextElementSibling;
            FadeToggle(cartContent);
        });
    }
};
export default Cart;
