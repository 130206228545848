import { SlideToggle, ToggleClass } from "../other/utils";
/**
 * Defines the custom menu scripts
 */
const Menu = {
    elements: document.getElementsByClassName('atom-menu'),
    init() {
        if (!this.elements || this.elements.length < 1) {
            return;
        }
        for (const menu of this.elements) {
            this.setupHamburgerMenu(menu);
            this.setupCollapsedMenu(menu);
        }
    },
    /**
     * Sets the click handler for the hamburger menu
     * @param menu The given menu element
     */
    setupHamburgerMenu(menu) {
        const hamburgerMenu = menu.querySelector('.atom-menu-hamburger');
        const menuWrapper = menu.querySelector('.menu');
        if (!hamburgerMenu) {
            return;
        }
        hamburgerMenu.addEventListener('click', (event) => {
            event.preventDefault();
            menu.classList.toggle('atom-menu-expanded');
            hamburgerMenu.classList.toggle('active');
            menuWrapper.classList.toggle('active');
        });
    },
    /**
     * Sets up the handlers for collapsed menus
     * @param menu The given menu element
     */
    setupCollapsedMenu(menu) {
        var _a;
        if (!menu.classList.contains('atom-menu-collapse')) {
            return;
        }
        const menuItemsWithChildren = menu.querySelectorAll('.menu-item-has-children > a');
        for (const menuItemAnchor of menuItemsWithChildren) {
            const subMenu = (_a = menuItemAnchor.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector('.sub-menu');
            menuItemAnchor.addEventListener('click', (event) => {
                event.preventDefault();
                ToggleClass(menuItemAnchor, 'active');
                SlideToggle(subMenu);
            });
        }
    }
};
export default Menu;
