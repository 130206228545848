import { FadeIn, FadeOut } from "../other/utils";
/**
 * Defines a social share element
 */
const Share = {
    elements: document.getElementsByClassName('atom-share-fixed'),
    init() {
        if (!this.elements || this.elements.length < 1) {
            return;
        }
        this.setupShare();
    },
    /**
     * Setup our sharing functionalities
     */
    setupShare() {
        if (document.documentElement.scrollHeight < window.innerHeight) {
            return;
        }
        let scrolled = false;
        window.addEventListener('scroll', () => {
            let scrollPosition = window.scrollY;
            if (scrollPosition > 5 && !scrolled) {
                for (const element of this.elements) {
                    FadeIn(element);
                }
                scrolled = true;
            }
            else if (scrollPosition < 5 && scrolled) {
                scrolled = false;
                for (const element of this.elements) {
                    FadeOut(element);
                }
            }
        });
    }
};
export default Share;
